@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.navbar {
  height: 60px;
  background-color: #303039;
  position: relative;
}

.logo-image{
  max-width:200px;
  height: auto;
  width: auto\9;
  overflow: hidden;
  margin-top: -6px;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #ccc9d3;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #d0bbbb;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #d0bbbb;
}